import { defineStore } from 'pinia'
import { apiGet } from '~/services/api'
import {
  PresentationAll,
  PresentationStandalone,
  PresentationObject,
} from '~/interfaces/tsPresentation'
import CryptoJS from 'crypto-js'

export const PresentationStore = defineStore('Presentation', {
  state: () => ({
    tableViewLoader: true,
    tablePaginatorLoader: false,
    mapViewLoader: true,
    agentInfoLoader: true,
    tableObjsData: {} as PresentationAll,
    standaloneData: {} as PresentationStandalone,
    similarObjData: [] as PresentationObject[],
    similarObjLoader: true,
    totalPages: 0,
    currentPage: 1,
    totalObjs: 0,
    isOwner: '',
    can_edit: false,
  }),
  actions: {
    async fetchTableData(uniqueCode: string) {
      this.tableViewLoader = true
      this.agentInfoLoader = true
      try {
        const { data } = await apiGet({
          url: `api/presentation/${uniqueCode}`,
        })
        if (data.value && data.value.data) {
          await this.fetchSimilarObj(uniqueCode)
          this.tableObjsData = data.value.data
          this.totalPages = data.value.meta.last_page
          this.totalObjs = data.value.meta.total
          this.tableViewLoader = false
          this.agentInfoLoader = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async fetchTableDataPagination(uniqueCode: string) {
      if (this.currentPage > this.totalPages || this.currentPage == this.totalPages) return
      this.currentPage = this.currentPage + 1
      this.tablePaginatorLoader = true
      try {
        const { data } = await apiGet({
          url: `api/presentation/${uniqueCode}`,
          query: {
            page: this.currentPage,
          },
        })
        if (data.value && data.value.data) {
          this.tableObjsData.objects = [...this.tableObjsData.objects, ...data.value.data.objects]
          this.tablePaginatorLoader = false
        }
      } catch (e) {
        console.log(e)
      }
    },

    async fetchSimilarObj(uniqueCode: string) {
      this.similarObjLoader = true
      try {
        const { data } = await apiGet({
          url: `api/presentation/${uniqueCode}/search-the-same-objects`,
        })
        if (data.value) {
          this.similarObjData = data.value.data
          this.similarObjLoader = false
        }
      } catch (e) {
        this.similarObjLoader = true

        console.log(e)
      }
    },
    async fetchStandaloneData(uniqueCode: string, obj_id: string) {
      try {
        const { data } = await apiGet({
          url: `api/presentation/${uniqueCode}/object/${obj_id}`,
        })
        if (data.value) {
          this.isOwner =
            data.value.data.presentation_owner ?? data.value.data.presentation_owner.toString()
          delete data.value.data.presentation_owner
          this.standaloneData = data.value.data
        }
      } catch (e) {
        console.log(e)
      }
    },

    checkPresentationOwner() {
      function generateHash(flag: string) {
        return CryptoJS.HmacSHA256(flag, 'Agentum_Will_Be_Live_Forever!').toString(CryptoJS.enc.Hex)
      }
      const flag = 'can_edit'
      const generatedHash = generateHash(flag)
      if (generatedHash == this.isOwner) {
        return true
      }
    },
  },
})
